<template>
    <div>
        <h1 class="my-4">Copiloto Karibu</h1>
        <b-form @submit.prevent="getAnswer">
            <div class="d-flex align-items-center container_div">
                <b-form-input class="form-control-lg input-field" v-model="question"
                    placeholder="Que preguntas tienes sobre Karibu o nuestros proyectos"></b-form-input>
                <b-button class="rounded-circle center-icon" type="submit" variant="primary">
                    <b-icon style="width: 24px; height: 24px;" icon="arrow-right-short"></b-icon>
                </b-button>
            </div>
        </b-form>
        <div v-if="analisis">
            <h2 class="my-4">Respuesta</h2>
            <p class="mb-3">{{ analisis }}</p>
            <h2 class="my-4">Documentación Vinculada</h2>
        </div>
        <div class="row">
            <div class="col-4" v-for="(item, index) in items" :key="index">
                <b-card :title="item.Pagina" :sub-title="item.Titulo" img-src="https://picsum.photos/600/300/?image=63"
                    img-alt="Image" img-top tag="article" style="max-width: 20rem;" class="mb-2">
                    <b-card-text class="d-flex flex-column flex-grow-1">
                        {{ item.Detalle }}
                    </b-card-text>

                    <b-button :href="item.URL" target="_blank" variant="primary">Bajar</b-button>
                </b-card>
            </div>
        </div>
        <div v-if="history" class="my-4">
            <h3 class="my-4">Historial</h3>
            <b-list-group>
                <b-list-group-item v-for="item in reversedList" :key="item.question">
                    <b>{{ item.question }}</b>
                    <p class="mb-3">{{ item.analisis }}</p>
                </b-list-group-item>
            </b-list-group>
        </div>
    </div>
</template>
  
<script lang="js">
import axios from '@/axios'
import Authorization from '@/authorization'

export default {
    data() {
        return {
            question: '',
            question_old: '',
            answer: null,
            analisis: '',
            history: null,
            pdf: {}
        }
    },
    title: 'Copiloto Karibu',
    methods: {
        async getAnswer() {
            if (this.analisis != '') {
                if (this.history == null) {
                    this.history = []
                }
                this.history.push({ question: this.question_old, analisis: this.analisis })
            }
            const response = await axios.post('/api/v1/search-answer',
                {
                    question: this.question,
                    history: this.history
                })
            if (response.status === 401) {
                Authorization.removeAccessToken()
                this.$router.push("/login")
            }
            this.answer = response.data.answer.answer
            this.analisis = response.data.answer.analisis
            this.question_old = this.question
        }
    },
    computed: {
        reversedList() {
            return this.history.slice().reverse()
        },
        ruta() {
            return 'pdf/' + this.pdf.pdf;
        },
        items() {
            return this.answer;
        }
    }
}
</script>
  
<style>
.container_div {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #ced4da;
    border-radius: 4px;
    padding: 5px;
}

.input-field {
    border: none;
    outline: none;
    width: 100%;
    padding: 0 10px;
}
</style>
<template>
    <div>
        <div class="row justify-content-center">
            <div class="col-md-6">
                <h2 class="text-center my-4">Iniciar sesión</h2>
                <b-form @submit="login">
                    <b-form-group id="username-input" label="Nombre de usuario" label-for="username"
                        description="Introduce tu nombre de usuario">
                        <b-form-input id="username" v-model="username" required
                            placeholder="Nombre de usuario"></b-form-input>
                    </b-form-group>

                    <b-form-group id="password-input" label="Contraseña" label-for="password"
                        description="Introduce tu contraseña">
                        <b-form-input id="password" v-model="password" type="password" required
                            placeholder="Contraseña"></b-form-input>
                    </b-form-group>
                    <div class="text-right">
                        <b-button type="submit" variant="primary" class="mt-3">Iniciar sesión</b-button>
                    </div>
                </b-form>
            </div>
        </div>
    </div>
</template>
  
<script lang="js">
import Authorization from '@/authorization';
import axios from '@/axios'
export default {
    data() {
        return {
            username: undefined,
            password: undefined
        }
    },
    methods: {
        async login(e) {
            e.preventDefault();
            const response = await axios.post('/login', {
                email: this.username,
                password: this.password,
            });

            if (response.status !== 200) {
                console.warn('Error al ingresar: ', response.data)
                return;
            }

            console.debug('Inicio de sesión correcto')

            Authorization.setAccessToken(response.data.access_token)

            this.$router.push("/")
        }
    }
}
</script>
  
<style scoped>
.container {
    margin-top: 50px;
}
</style>
  
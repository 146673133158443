<template>
  <div class="container" id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  data() {
    return {}
  },
  title: 'Copiloto Karibu',
}
</script>